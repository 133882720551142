import * as S from "./styles";
import Toggle from "../../component/toggle";
import TagInput from "../../component/tagInput";
import { useInput } from "../../hooks/useInput";
import Layout from "../../component/layout";
import { THEME } from "../../constant";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function MyProfile() {
  const { value: nameValue, onChange: onNameChange } = useInput();
  const { value: majorValue, onChange: onMajorChange } = useInput();
  const { value: jobValue, onChange: onJobChange } = useInput();
  const { value: joinDateValue, onChange: onJoinDateChange } = useInput();
  const { value: phoneValue, onChange: onPhoneChange } = useInput();
  const { value: emailValue, onChange: onEmailChange } = useInput();
  const { value: githubValue, onChange: onGithubChange } = useInput();
  const { value: blogValue, onChange: onBlogChange } = useInput();
  const { value: passwordValue, onChange: onPasswordChange } = useInput();
  const { value: commentValue, onChange: onCommentChange } = useInput();
  const [tags, setTags] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    setLoading(true);
    const result = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/posts`,
      {
        headers: {},
        data: {
          name: nameValue,
          major: majorValue,
          joinDate: joinDateValue,
          job: jobValue,
          tags: tags,
          comment: commentValue,
          phone: phoneValue,
          email: emailValue,
          github: githubValue,
          blog: blogValue,
          password: passwordValue,
        },
      }
    );
    setLoading(false);
    history.push("/");
  };
  return (
    <Layout>
      <S.TitleWrapper>
        <S.Title>프로필 등록</S.Title>
      </S.TitleWrapper>
      <S.CoreInfoWrapper>
        <S.LabelWrapper>
          <S.Label>이름*</S.Label>
          <S.Input
            value={nameValue}
            onChange={onNameChange}
            placeholder="홍길동"
          />
          <S.Label>학과</S.Label>
          <S.Input
            value={majorValue}
            onChange={onMajorChange}
            placeholder="OO학과"
          />
          <S.Label>소속</S.Label>
          <S.Input
            value={jobValue}
            onChange={onJobChange}
            placeholder="학부생/대학원생/직장인(OO 회사) 등"
          />
          <S.Label>학번*</S.Label>
          <S.Input
            value={joinDateValue}
            onChange={onJoinDateChange}
            placeholder="2021"
          />
        </S.LabelWrapper>
      </S.CoreInfoWrapper>
      <S.SubInfoWrapper>
        <S.TagWrapper>
          <S.Label>태그</S.Label>
          <TagInput tagList={tags} setTagList={setTags} tagColor={THEME.red} />
        </S.TagWrapper>
        <S.ContactWrapper>
          <S.Label>자기소개</S.Label>
          <S.CommentInput value={commentValue} onChange={onCommentChange} />
          <S.Label>전화번호</S.Label>
          <S.Input
            value={phoneValue}
            onChange={onPhoneChange}
            placeholder="010-XXXX-XXXX"
          />
          <S.Label>이메일</S.Label>
          <S.Input
            value={emailValue}
            onChange={onEmailChange}
            placeholder="kucc@korea.ac.kr"
          />
          <S.Label>Github</S.Label>
          <S.Input
            value={githubValue}
            onChange={onGithubChange}
            placeholder="Github ID"
          />
          <S.Label>Blog</S.Label>
          <S.Input
            value={blogValue}
            onChange={onBlogChange}
            placeholder="https://www.blog.com"
          />
          <S.Label>비밀번호</S.Label>
          <S.Input
            value={passwordValue}
            onChange={onPasswordChange}
            placeholder="password"
            type="password"
          />
        </S.ContactWrapper>
      </S.SubInfoWrapper>
      {!loading && <S.Button onClick={() => handleSubmit()}>등록</S.Button>}
    </Layout>
  );
}
