import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Profile } from "../../constant";
import { DummyProfiles } from "../../dummy";

export function useMain() {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState(DummyProfiles);
  const [filteredProfiles, setFilteredProfiles] = useState(DummyProfiles);
  const [profileModal, setProfileModal] = useState(null as unknown as number);

  useEffect(() => {
    (async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/posts?populate=*`,
        {
          headers: {},
        }
      );
      console.log(result.data.data);
      if (result?.data?.data) {
        setProfiles(result.data.data);
        setFilteredProfiles(result.data.data);
        setLoading(false);
      }
    })();
  }, []);

  return {
    loading,
    profiles,
    filteredProfiles,
    profileModal,
    setProfileModal,
  };
}
