import Modal from "../../component/modal";
import * as S from "./styles";
import { Email, GitHub, Language, Phone } from "@material-ui/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { DummyProfiles } from "../../dummy";
import { Profile } from "../../constant";

interface ProfileProps {
  profileId: number;
  setOpen: (n: number) => void;
}

export default function ProfileModal({ profileId, setOpen }: ProfileProps) {
  const [item, setItem] = useState(DummyProfiles[0]);
  useEffect(() => {
    (async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/posts?populate=*`,
        {
          headers: {},
        }
      );
      console.log(result.data.data);
      if (result?.data?.data) {
        setItem(
          result.data.data.filter((item: Profile) => item.id === profileId)[0]
        );
      }
    })();
  }, []);

  return (
    <Modal isOpen={profileId !== null} setOpen={setOpen}>
      <S.Main>
        <S.Contents>
          <div>
            <S.TitleRow>
              <S.Name>{item.attributes.name}</S.Name>
              <S.MajorAndJob>{`${item.attributes.major} ${
                item.attributes.joinDate
              }${item.attributes.major && item.attributes.job && " · "}${
                item.attributes.job
              }`}</S.MajorAndJob>
            </S.TitleRow>
            <S.Comment>{item.attributes.comment}</S.Comment>
            <S.Table>
              <S.Section>태그</S.Section>
              <S.Tags>
                {item.attributes.tags.map((tag, index) => {
                  return <S.Tag key={tag}>{tag}</S.Tag>;
                })}
              </S.Tags>
            </S.Table>
          </div>
          <div>
            <S.Section>연락처</S.Section>
            <S.ContactList>
              {item.attributes.phone && (
                <S.ContactItem>
                  <Phone />
                  {item.attributes.phone}
                </S.ContactItem>
              )}
              {item.attributes.email && (
                <S.ContactItem>
                  <Email />
                  {item.attributes.email}
                </S.ContactItem>
              )}
              {item.attributes.github && (
                <a
                  href={`https://github.com/${item.attributes.github}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <S.ContactItem>
                    <GitHub />
                    {item.attributes.github}
                  </S.ContactItem>
                </a>
              )}
              {item.attributes.blog && (
                <a href={item.attributes.blog} target="_blank" rel="noreferrer">
                  <S.ContactItem>
                    <Language />
                    {item.attributes.blog
                      .replace(/^https?:\/\//i, "")
                      .replace(/^http?:\/\//i, "")}
                  </S.ContactItem>
                </a>
              )}
            </S.ContactList>
          </div>
        </S.Contents>
      </S.Main>
    </Modal>
  );
}
