import { Notifications } from "@material-ui/icons";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "../../context/user";
import * as S from "./styles";

export default function Header() {
  const history = useHistory();

  return (
    <S.Header>
      <S.Layout>
        <div onClick={() => history.push("/")}>
          <S.Logo
            src="https://user-images.githubusercontent.com/41494099/86317182-c58a8900-bc69-11ea-9a6e-dfdb1e0141be.png"
            alt="메인 로고"
          />
          <S.Title>Networks🌐</S.Title>
        </div>
      </S.Layout>
    </S.Header>
  );
}
