import * as S from "./styles";
import {
  Add,
  Email,
  GitHub,
  Language,
  Phone,
  Search,
} from "@material-ui/icons";
import Header from "../../component/header";
import { useMain } from "./hooks";
import Layout from "../../component/layout";
import Profile from "../profile";

export default function Main() {
  const { loading, filteredProfiles, profileModal, setProfileModal } =
    useMain();
  return (
    <>
      <Layout>
        <S.ProfileList>
          {!loading &&
            filteredProfiles.map((item, index) => {
              return (
                <S.ProfileItem
                  key={`${item.attributes.name}${item.attributes.joinDate}`}
                  onClick={() => setProfileModal(item.id)}
                >
                  <S.Content>
                    <div>
                      <S.TitleRow>
                        <S.Name>{item.attributes.name}</S.Name>
                        <S.MajorAndJob>{`${item.attributes.major} ${
                          item.attributes.joinDate
                        }${
                          item.attributes.major && item.attributes.job && " · "
                        }${item.attributes.job}`}</S.MajorAndJob>
                      </S.TitleRow>
                      <S.Comment>{item.attributes.comment}</S.Comment>
                      <S.Table>
                        <S.Section>태그</S.Section>
                        <S.Tags>
                          {item.attributes.tags.map((tag) => {
                            return <S.Tag key={tag}>{tag}</S.Tag>;
                          })}
                        </S.Tags>
                      </S.Table>
                    </div>
                    <div>
                      <S.Section>연락처</S.Section>
                      <S.ContactList>
                        {item.attributes.phone && (
                          <S.ContactItem>
                            <Phone />
                            {item.attributes.phone}
                          </S.ContactItem>
                        )}
                        {item.attributes.email && (
                          <S.ContactItem>
                            <Email />
                            {item.attributes.email}
                          </S.ContactItem>
                        )}
                        {item.attributes.github && (
                          <a
                            href={`https://github.com/${item.attributes.github}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <S.ContactItem>
                              <GitHub />
                              {item.attributes.github}
                            </S.ContactItem>
                          </a>
                        )}
                        {item.attributes.blog && (
                          <a
                            href={item.attributes.blog}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <S.ContactItem>
                              <Language />
                              {item.attributes.blog
                                .replace(/^https?:\/\//i, "")
                                .replace(/^http?:\/\//i, "")}
                            </S.ContactItem>
                          </a>
                        )}
                      </S.ContactList>
                    </div>
                  </S.Content>
                </S.ProfileItem>
              );
            })}
        </S.ProfileList>
        <S.AddButton to="/myProfile">
          <Add />
        </S.AddButton>
      </Layout>
      {profileModal !== null && (
        <Profile profileId={profileModal} setOpen={setProfileModal} />
      )}
    </>
  );
}
