export const DummyProfiles = [
  {
    id: 0,
    attributes: {
      imageURL: {
        data: {
          attributes: {
            url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png",
          },
        },
      },
      name: "",
      joinDate: "",
      major: "",
      job: "",
      tags: [],
      comment: ``,
      phone: "",
      email: "",
      github: "",
      blog: "",
    },
  },
];
